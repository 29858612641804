import type { Agegroup } from "~/ts/interfaces/Agegroup";
import type { Department } from "~/ts/interfaces/Department";
import type { Document } from "~/ts/interfaces/Document";
import type { FAQuestion } from "~/ts/interfaces/FAQuestion";
import type { Gallery } from "~/ts/interfaces/Gallery";
import type { StrapiData, StrapiDataCollection } from "~/ts/interfaces/strapi/StrapiData";

export class ApiUtil {

    static async getAgeGroups(populate = false): Promise<Agegroup[]> {
        const { public: { apiUrl } } = useRuntimeConfig();
        const { data } = await useFetch(`${apiUrl}/api/agegroups`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
            params: {
                populate: `${populate ? '*' : ''}`,
            },
        });
        return data.value as Agegroup[];
    }

    static async getAgeGroup(id: string, populate = false): Promise<Agegroup> {
        const { public: { apiUrl } } = useRuntimeConfig();
        const { data } = await useFetch(`${apiUrl}/api/agegroups/${id}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
            params: {
                populate: `${populate ? '*' : ''}`,
            },
        });
        return data.value as Agegroup;
    }

    static async getDocuments(populate = false): Promise<Document[]> {
        const { public: { apiUrl } } = useRuntimeConfig();
        const { data } = await useFetch(`${apiUrl}/api/documents`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
            params: {
                // populate: `${populate ? '*' : ''}`,
                'populate[department]': '*',
                'populate[agegroup]': '*',

            },
        });
        return data.value as Document[];
    }

    static async getGalleries(populate = false): Promise<Gallery[]> {
        const { public: { apiUrl } } = useRuntimeConfig();
        const { data } = await useFetch(`${apiUrl}/api/galleries`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
            params: {
                populate: `${populate ? '*' : ''}`,
            },
        });
        return data.value as Gallery[];
    }

    static async getGallery(id: string, populate = false): Promise<StrapiData<Gallery>> {
        const { public: { apiUrl } } = useRuntimeConfig();
        const { data } = await useFetch(`${apiUrl}/api/galleries/${id}?populate[images]=*&populate[activity]=*`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
            params: {
                populate: `${populate ? '*' : ''}`,
            },
        });
        return data.value as StrapiData<Gallery>;
    }

    static async getFaq(populate = false): Promise<StrapiDataCollection<FAQuestion>> {
        const { public: { apiUrl } } = useRuntimeConfig();
        const { data } = await useFetch(`${apiUrl}/api/faqs`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
            params: {
                populate: `${populate ? '*' : ''}`,
            },
        });
        return data.value as StrapiDataCollection<FAQuestion>;
    }

    static async getDepartments(populate = true): Promise<StrapiDataCollection<Department>> {
        const { public: { apiUrl } } = useRuntimeConfig();
        const { data } = await useFetch(`${apiUrl}/api/departments`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
            params: {
                // populate: `${populate ? '*' : ''}`,
                'populate[leaders][populate]': '*',
                'populate[Contact]': '*',
            },
        });
        return data.value as StrapiDataCollection<Department>;
    }
}